<template>
    <div class="m-auto" style="max-width: 960px;">
        <el-card header="基本信息" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">土地行政区划</span>
                    <span class="property-value">{{land.regionName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包开始日期</span>
                    <span class="property-value">{{$moment.sd(land.beginContractDate)}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">承包结束日期</span>
                    <span class="property-value">{{$moment.sd(land.endContractDate)}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">编号</span>
                    <span class="property-value">{{land.code}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">名称</span>
                    <span class="property-value">{{land.name}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">类型</span>
                    <span class="property-value">{{land.typeName}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">面积</span>
                    <span class="property-value">{{land.area}} 亩</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">口粮地面积</span>
                    <span class="property-value">{{land.rationArea}} 亩</span>
                </el-col>
            </el-row>
        </el-card>

        <el-card v-if="land.isMappingCompleted" header="位置" shadow="never" class="m-3">
            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">中心点经度</span>
                    <span class="property-value">{{land.centerLongitude}}</span>
                </el-col>
                <el-col :span="12" class="property-group">
                    <span class="property-name">中心点纬度</span>
                    <span class="property-value">{{land.centerLatitude}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="12" class="property-group">
                    <span class="property-name">默认缩放级别</span>
                    <span class="property-value">{{land.defaultZoom}}</span>
                </el-col>
            </el-row>

            <el-row :gutter="8">
                <el-col :span="24" class="property-group">
                    <span class="property-name">边界</span>
                    <div v-if="showMap">
                        <el-amap :center="[land.centerLongitude,land.centerLatitude]" :zoom="land.defaultZoom"
                            style="height: 400px;">
                            <el-amap-polygon :path="land.boundary.map(p=>[p.longitude,p.latitude])"
                                stroke-color="#409eff" fill-color="#409eff" fill-opacity="0.25" />
                            <el-amap-marker :position="[land.centerLongitude,land.centerLatitude]" />
                        </el-amap>
                    </div>
                </el-col>
            </el-row>
        </el-card>

        <el-card header="照片" shadow="never" class="m-3">
            <el-image :src="land.photoUrl" :preview-src-list="[land.photoUrl]" fit="cover" class="w-100" />
        </el-card>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                id: this.$route.query.id,
                land: {},
                showMap: false,
            };
        },
        methods: {
            async loadLand() {
                let response = await this.$axios.get('/api/Land/GetLand', { params: { id: this.id } });
                this.land = response.data;
                document.title = this.land.code;

                await this.$delay(1000);
                this.showMap = true;
            },
        },
        created() {
            document.title = this.$store.state.applicationName;

            this.loadLand();
        },
    };
</script>

<style>
    .property-group {
        line-height: 40px;
    }

    .property-name {
        display: inline-block;
        color: gray;
    }

    .property-name::after {
        content: '：';
    }

    .property-value {
        display: inline-block;
    }
</style>